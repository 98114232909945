import * as user from "./user";
import * as staff from "./staff";
import * as auth from "./auth";
import * as presentCouponContent from "./presentCouponContent";
import * as autoCouponContent from "./autoCouponContent";
import * as privilegeCouponContent from "./privilegeCouponContent";
import * as store from "./store";
import * as branch from "./branch";
import * as notice from "./notice";
import * as stampCard from "./stampCardContent";
import * as membersCardContent from "./membersCardContent";
import * as membersCardRank from "./membersCardRank";
import * as noticeTemplate from "./noticeTemplate";
import * as dashboard from "./dashboard";
import * as ticketFeature from "./ticketFeature";
import * as ticketContents from "./ticketContents";
import * as tickets from "./ticket";
import * as charge from "./charge";
import * as menu from "./menu";
import * as orderFeature from "./orderFeature";
import * as ecFeature from "./ecFeature";
import * as bannerFeature from "./bannerFeature";
import * as banner from "./banner";
import * as optionItem from "./optionItem";
import * as owner from "./owner";
import * as storeStaff from "./storeStaff";
import * as activityLog from "./activityLog";
import * as serviceContent from "./serviceContent";
import * as subscriptionContent from "./subscriptionContent";
import * as calendarFeature from "./calendarFeature";
import * as openTime from "./openTime";
import * as event from "./event";
import * as discount from "./discount";
import * as userGroup from "./userGroup";
import * as connection from "./connection";
import * as rankingFeature from "./rankingFeature";

// owner
import * as ownerStaff from "./owner/staff";
import * as ownerStore from "./owner/store";
import * as ownerOwner from "./owner/owner";
import * as ownerOwnerStaffs from "./owner/ownerStaffs";
import * as ownerBankAccount from "./owner/bankAccount";
import * as ownerCharge from "./owner/charge";
import * as ownerStoreStaff from "./owner/storeStaff";

export const userApi = user;
export const staffApi = staff;
export const authApi = auth;
export const presentCouponContentApi = presentCouponContent;
export const autoCouponContentApi = autoCouponContent;
export const privilegeCouponApi = privilegeCouponContent;
export const storeApi = store;
export const branchApi = branch;
export const noticeApi = notice;
export const stampCardApi = stampCard;
export const membersCardContentApi = membersCardContent;
export const membersCardRankApi = membersCardRank;
export const noticeTemplateApi = noticeTemplate;
export const dashboardApi = dashboard;
export const ticketFeatureApi = ticketFeature;
export const ticketContentsApi = ticketContents;
export const ticketApi = tickets;
export const chargeApi = charge;
export const menuApi = menu;
export const orderFeatureApi = orderFeature;
export const ecFeatureApi = ecFeature;
export const bannerFeatureApi = bannerFeature;
export const bannerApi = banner;
export const optionItemApi = optionItem;
export const ownerApi = owner;
export const storeStaffApi = storeStaff;
export const activityLogApi = activityLog;
export const serviceContentApi = serviceContent;
export const subscriptionContentApi = subscriptionContent;
export const calendarFeatureApi = calendarFeature;
export const openTimeApi = openTime;
export const eventApi = event;
export const discountApi = discount;
export const userGroupApi = userGroup;
export const connectionApi = connection;
export const rankingFeatureApi = rankingFeature;

// owner
export const ownerStaffApi = ownerStaff;
export const ownerStoreApi = ownerStore;
export const ownerOwnerApi = ownerOwner;
export const ownerOwnerStaffsApi = ownerOwnerStaffs;
export const ownerBankAccountApi = ownerBankAccount;
export const ownerChargeApi = ownerCharge;
export const ownerStoreStaffApi = ownerStoreStaff;
